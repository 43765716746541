import { AttachmentSearchQuerySerialized } from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';
import {
  IssueId,
  IssuesResponse,
  IssuesResponseSerialized,
  SupportRequestsResponse,
  SupportRequestId,
  RequestStatus,
  SupportRequestsResponseSerialized,
  AppSupportRequestBrief,
  AppIssueBrief,
  IssueSort,
  SupportRequestSort,
  IssuesAttachmentsSearchResponse,
  IssuesAttachmentsSearchResponseSerialized,
  SupportRequestsAttachmentSearchResponse,
  AppSupportRequest,
  AppIssue,
  ClusterizationTaskBrief,
  ClusterizationRun,
} from '@playq/services-beetle';

import { IBaseSort, IQuerySerialized } from '/common/models';

export enum SupportContentType {
  SUPPORT_REQUESTS = 'support_requests',
  ISSUES = 'issues',
  ATTACHMENTS = 'attachments',
  ISSUES_ATTACHMENTS = 'issues_attachments',
  SUPPORT_REQUESTS_ATTACHMENTS = 'support_requests_attachments',
  TASKS = 'tasks',
  EMPTY = '',
}
export interface AttachmentSearchQueryProps extends IQuerySerialized {
  search: AttachmentSearchQuerySerialized;
  type: SupportContentType;
}

export interface Params {
  env: Environment;
  appName: string;
}
export interface IBeetleParams extends Params {
  app: string;
  id: string;
  type: SupportContentType;
}
export interface BeetleNavigationParams {
  type: SupportContentType;
  appName: string;
  env: Environment;
}

export interface IRange {
  [key: string]: Date | string;
  from: Date;
  to: Date;
}
export enum ActionType {
  SET_RANGE = 'set_range',
}

export interface TGlobalState {
  range: IRange;
}

export type Action = ISetRange;

export interface CustomKeyRange {
  key: string;
  value: Date;
}
export interface ISetRange {
  type: ActionType.SET_RANGE;
  payload: CustomKeyRange;
}
export interface TAttachmentPickerProps {
  range: IRange;
  handleRange: (key: string, value: Date) => void;
}
export type InjectedProps = {
  params: IBeetleParams;
  readonly: boolean;
};
export interface IStyleProps {
  count?: number;
  isAttachments?: boolean;
}
export type IssueType = SupportContentType.ISSUES | SupportContentType.SUPPORT_REQUESTS;
export type IssueWithAttachments = SupportContentType.ISSUES_ATTACHMENTS;
export type SortableContentType = IssueType | IssueWithAttachments;

export interface UpdateResponseParams<R, ID, S, Q, T> {
  prevResponse: R;
  Constructor: new (prev: Q) => R;
  id: ID;
  status: S;
  key: keyof R;
  updateFn: (item: T, status: S) => T;
}
export interface UpdateBatchResponseParams<R, ID, S, Q, T> extends Omit<UpdateResponseParams<R, ID, S, Q, T>, 'id'> {
  ids: ID[];
}
export interface TCommonUpdateProps<R> extends TUpdateData {
  prevResponse: R;
}
export interface TCommonUpdateBatchProps<R> extends Omit<TCommonUpdateProps<R>, 'id'> {
  ids: TBettleId[];
}
export type TIssuesParams = UpdateBatchResponseParams<
  IssuesResponse,
  IssueId,
  boolean,
  IssuesResponseSerialized,
  AppIssueBrief
>;
export type TRequestsParams = UpdateBatchResponseParams<
  SupportRequestsResponse,
  SupportRequestId,
  RequestStatus,
  SupportRequestsResponseSerialized,
  AppSupportRequestBrief
>;
export type TIssuesAttachmentBatchParams = UpdateBatchResponseParams<
  IssuesAttachmentsSearchResponse,
  IssueId,
  boolean,
  IssuesAttachmentsSearchResponseSerialized,
  AppIssueBrief
>;

export type TIssueParams = UpdateResponseParams<
  IssuesResponse,
  IssueId,
  boolean,
  IssuesResponseSerialized,
  AppIssueBrief
>;
export type TRequestParams = UpdateResponseParams<
  SupportRequestsResponse,
  SupportRequestId,
  RequestStatus,
  SupportRequestsResponseSerialized,
  AppSupportRequestBrief
>;
export type TIssuesAttachmentParams = UpdateResponseParams<
  IssuesAttachmentsSearchResponse,
  IssueId,
  boolean,
  IssuesAttachmentsSearchResponseSerialized,
  AppIssueBrief
>;

export type TBettleId = IssueId | SupportRequestId;
export type TBettleStatus = boolean | RequestStatus;
export type TIssueOrRequestSort = IssueSort | SupportRequestSort | IBaseSort<string>;
export type TIssueOrSupportBrief = AppIssueBrief | AppSupportRequestBrief;
export type TUpdateData = { id: TBettleId; status: TBettleStatus };
export type TUpdateBatchData = { ids: TBettleId[]; status: TBettleStatus };
export type AttachmentsResponse = IssuesAttachmentsSearchResponse | SupportRequestsAttachmentSearchResponse;
export type Brief = AppIssueBrief | AppSupportRequestBrief;

export type ISort = IssueSort | SupportRequestSort;

export type TContentProps = { isSupportRequestPage: boolean };

export type PossibleItemTypes =
  | AppIssueBrief
  | AppSupportRequestBrief
  | AppSupportRequest
  | AppIssue
  | ClusterizationTaskBrief
  | ClusterizationRun;

export interface TCacheNotes {
  addNote: (currentNote: NoteSerializedProp) => void;
  removeNote: (noteId: string) => void;
  updateNote: (currentNote: NoteSerializedProp) => void;
  updateResolved: ((status: boolean) => void) | undefined;
}
export interface TConfigValue {
  entity: AppIssue | AppSupportRequest;
  cache: TCacheNotes;
  generalId: IssueId | SupportRequestId;
  updatedAt: number;
}
export interface NoteSerializedProp {
  id: string;
  note: string;
}
export interface IContextConfigProps {
  value: TConfigValue;
}
