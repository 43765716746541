import { Typography } from '@mui/material';

import { ConfirmDialogType, IConfirmProps, confirmDialog } from '/common/ConfirmDialog';

import { PossibleItemTypes } from '../types';

export interface IConfirmDialogProps extends Pick<IConfirmProps, 'onSuccess' | 'title'> {
  selectedItems: PossibleItemTypes[];
}
export function getItemDisplayText(item: PossibleItemTypes): string | undefined {
  if ('titleTranslated' in item) return item.titleTranslated;
  if ('name' in item) return item.name;
  return item.id?.id;
}

export const deleteConfirmDialog = ({ onSuccess, selectedItems }: IConfirmDialogProps) => {
  const quantityLabel = selectedItems.length > 1 ? 'items' : 'item';

  const textIds = selectedItems.map((item) => (
    <Typography key={item.id?.id} paragraph sx={{ '&:last-child': { marginBottom: 0 } }}>
      {getItemDisplayText(item)}
    </Typography>
  ));

  return confirmDialog({
    title: `Delete ${selectedItems.length} ${quantityLabel}?`,
    text: textIds,
    type: ConfirmDialogType.Error,
    closeButton: { label: 'Cancel' },
    successButton: { label: 'Ok' },
    onSuccess,
  });
};
