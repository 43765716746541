import { useCallback, useMemo } from 'react';

import { MetaKeyWithCounter } from '@playq/services-beetle';

import { mapMetaToKeyValuePairsSerialized } from '/helpers';
import { QueryFilter } from '/common/models';

import { existMeta, isEmptyFilter } from '../helpers';

export const useKeyValuePairsFromEntities = <T>({
  entities,
  metaKeys,
  initialFilterBy,
  getMeta,
}: {
  metaKeys?: MetaKeyWithCounter[];
  entities: T[];
  initialFilterBy?: QueryFilter;
  getMeta: (item: T) => { [key: string]: string };
}) => {
  const filterData = useCallback(() => {
    return entities
      .map(getMeta)
      .flat()
      .filter((meta) => existMeta(meta));
  }, [entities, getMeta]);

  const getAllMeta = useCallback(() => {
    if (initialFilterBy && metaKeys && isEmptyFilter(initialFilterBy)) {
      return metaKeys.reduce((acc: { [key: string]: string }[], cur) => {
        cur.values.forEach((value) => {
          acc.push({ [cur.key]: value.value });
        });
        return acc;
      }, []);
    }

    return filterData();
  }, [filterData, initialFilterBy, metaKeys]);

  const metaData = useMemo(() => {
    const allMeta = getAllMeta();
    return mapMetaToKeyValuePairsSerialized(allMeta);
  }, [getAllMeta]);

  const filteredData = useMemo(() => {
    const allMeta = filterData() ?? [];
    return mapMetaToKeyValuePairsSerialized(allMeta);
  }, [filterData]);
  return {
    filteredData,
    metaData,
  };
};
