import { Accepted, Declined, BeingProcessed, Resolved, RequestStatus, Sent } from '@playq/services-beetle';

import { OptionalPropsType } from '/shared/EntityCommentDialog';

import { UpdateRequestType } from '../IssuesSupportRequests/components/tables/types';

export const createRequestStatusByTitle = (title: UpdateRequestType): RequestStatus | null => {
  switch (title) {
    case 'Accepted':
      return new Accepted();
    case 'Declined':
      return new Declined();
    case 'BeingProcessed':
      return new BeingProcessed();
    case 'Resolved':
      return new Resolved();
    case 'Sent':
      return new Sent();
    default:
      return null;
  }
};

export const handleUpdateRequest = async ({
  title,
  dialog,
  cb,
}: {
  title: string;
  dialog: (updatedComment: string, options?: Partial<OptionalPropsType>) => Promise<string | undefined>;
  cb: (title: RequestStatus) => void;
}) => {
  const currentStatus = createRequestStatusByTitle(title as UpdateRequestType);
  if (!currentStatus) return;

  currentStatus.at = new Date();
  if (currentStatus instanceof Resolved || currentStatus instanceof Declined) {
    const comment = await dialog('');
    if (comment) currentStatus.message = comment;
  }

  cb(currentStatus);
};
