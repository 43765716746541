import { AppSupportRequestSerialized, AppIssueSerialized } from '@playq/services-beetle';

import { Universal } from '../IssuesSupportRequests/components/types';

export const checkFieldTranslated = (fields: AppIssueSerialized | AppSupportRequestSerialized) => {
  const secondPartField = 'Translated';
  const comparedArrayField = [];
  for (const key in fields) {
    const fieldsString = key as Universal;
    if (key.includes(secondPartField)) {
      const splitFiled = key.split(secondPartField).join('') as Universal;
      const compareFieldsWithTranslated =
        fields[fieldsString] !== undefined ? fields[fieldsString] !== fields[splitFiled] : false;
      comparedArrayField.push(compareFieldsWithTranslated);
    }
  }
  return comparedArrayField;
};
