import { SetStateAction } from 'react';

import { KeyValueFilter, KeyValuePair } from '@playq/octopus-common';
import { MetaValueWithCounter, MetaKeyWithCounter } from '@playq/services-beetle';

import { IQuery, QueryFilter } from '/common/models';
export const convertBeetleMetaToTagsWithCounters = <BeetleType extends { meta: { [key: string]: string } }>(
  accumulatedTags: MetaKeyWithCounter[],
  { meta }: BeetleType
): MetaKeyWithCounter[] => {
  const metaKeys = Object.keys(meta);
  if (!metaKeys.length) return accumulatedTags;

  metaKeys.forEach((metaKey) => {
    const metaValue = meta[metaKey];
    const existingTag = findOrCreateTag(accumulatedTags, metaKey);
    incrementTagCount(existingTag);
    addOrUpdateMetaValue(existingTag, metaValue);
  });
  return accumulatedTags;
};

const findOrCreateTag = (tags: MetaKeyWithCounter[], key: string): MetaKeyWithCounter => {
  const existingTag = tags.find((tag) => tag.key === key);
  if (!existingTag) {
    const newTag = new MetaKeyWithCounter();
    newTag.key = key;
    newTag.count = 1;
    newTag.values = [];
    tags.push(newTag);
    return newTag;
  }
  return existingTag;
};

const incrementTagCount = (tag: MetaKeyWithCounter): void => {
  tag.count += 1;
};

const addOrUpdateMetaValue = (tag: MetaKeyWithCounter, value: string): void => {
  const existingValue = tag.values.find((valueWithCounter) => valueWithCounter.value === value);
  if (!existingValue) {
    const newValue = new MetaValueWithCounter();
    newValue.value = value;
    newValue.count = 1;
    tag.values.push(newValue);
  } else {
    existingValue.count += 1;
  }
};

const emptyArray: [] = [];

export type SelectMetaTagPayload<BeetleSortType> = {
  key: string;
  value?: string;
  query: IQuery<string, BeetleSortType>;
  setQuery: (value: SetStateAction<IQuery<string, BeetleSortType>>) => void;
};

export const selectMetaTag = <BeetleSortType>({
  key,
  value,
  query,
  setQuery,
}: SelectMetaTagPayload<BeetleSortType>) => {
  const prevFilterByMeta = query.filterBy.Meta as KeyValueFilter | undefined;
  const prevPairs = prevFilterByMeta?.pairs || emptyArray;
  const isAlreadySelected = prevPairs.some(
    ({ key: pairKey, value: pairValue }) => pairKey === key && pairValue === value
  );
  if (isAlreadySelected) {
    return;
  }
  const newQuery = { ...query };
  const newFilter = new KeyValueFilter(prevFilterByMeta?.serialize());
  const newPair = new KeyValuePair({ key, value });
  newFilter.pairs = prevPairs.concat(newPair);
  newQuery.filterBy = { ...query.filterBy, Meta: newFilter };
  setQuery(newQuery);
};

export const existMeta = (meta: { [key: string]: string }) => {
  return Object.keys(meta).length > 0;
};

export const handleEntriesMeta = (metaEntries?: { [key: string]: string }) =>
  !metaEntries ? [] : Object.entries(metaEntries);

export const isEmptyFilter = (filterBy: QueryFilter) => !Object.keys(filterBy).length;
