import { useSyncExternalStore } from 'react';

import { parseJSON } from '/helpers';

export function useLocalStorage<T>(key: string, fallback: T, cb?: (value: T | null) => void) {
  const readValue = () => {
    return localStorage.getItem(key) ?? fallback;
  };

  const subscribe = (callback: () => void) => {
    const onStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        callback();
        const updatedValue = event.newValue ? (parseJSON(event.newValue) as T) : null;
        cb?.(updatedValue);
      }
    };
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  };

  const value = useSyncExternalStore(subscribe, readValue, () => fallback);

  const setValue = (newValue: T) => {
    try {
      localStorage.setItem(key, JSON.stringify(newValue));
      window.dispatchEvent(new StorageEvent('storage', { key, newValue: JSON.stringify(newValue) }));
    } catch (error) {
      console.error('Error setting localStorage:', error);
    }
  };

  const removeItem = () => {
    try {
      localStorage.removeItem(key);
      window.dispatchEvent(new StorageEvent('storage', { key, newValue: null }));
    } catch (error) {
      console.error('Error removing localStorage item:', error);
    }
  };
  const parsedValue = parseJSON(value as string) as T;
  return [parsedValue, setValue, removeItem] as const;
}
