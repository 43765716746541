import { useCallback, useReducer } from 'react';

import { TextFilterSerialized } from '@playq/octopus-common';
import { AttachmentSearchQuery } from '@playq/services-beetle';

import { PersistedQueryKeys, QueryFilter } from '/common/models';
import { createStorage, sessionStorageMethods } from '/storage';

import { Action, ActionType, TGlobalState } from '../types';

export const store = createStorage({ prefix: PersistedQueryKeys.Attachments, storage: sessionStorageMethods });
const beetleLookupReducer = (state: TGlobalState, action: Action): TGlobalState => {
  switch (action.type) {
    case ActionType.SET_RANGE:
      return { ...state, range: { ...state.range, [action.payload.key]: action.payload.value } };
    default:
      return state;
  }
};
const initialState = () => {
  const attachmentsDate = store.get(PersistedQueryKeys.Attachments);
  const parsedData = attachmentsDate ? (JSON.parse(attachmentsDate) as { from: string; to: string }) : null;
  const prevMonth = new Date().setMonth(new Date().getMonth() - 1);
  return {
    range: {
      from: new Date(parsedData?.from ?? prevMonth),
      to: new Date(parsedData?.to ?? new Date()),
    },
  };
};

export const useSearchQuery = () => {
  const [state, dispatch] = useReducer(beetleLookupReducer, initialState());
  const searchAttachmentQuery = useCallback(
    (searchText: QueryFilter): AttachmentSearchQuery => {
      const query = searchText.Any?.serialize() as TextFilterSerialized;
      return new AttachmentSearchQuery({
        from: state.range.from.toISOString(),
        to: state.range.to.toISOString(),
        query: query?.text || '',
      });
    },
    [state.range.from, state.range.to]
  );

  const handleRange = useCallback((key: string, value: Date) => {
    dispatch({
      type: ActionType.SET_RANGE,
      payload: {
        key,
        value,
      },
    });
  }, []);
  return { searchAttachmentQuery, handleRange, range: state.range };
};
