import { blue, green, grey, orange, red, yellow } from '@mui/material/colors';
import { PaletteColor } from '@mui/material';

import { AppIssueBrief } from '@playq/services-beetle';

import { unresolveIssue } from '../constants';
import { IResolvedColor } from '../IssuesSupportRequests/types';
import { UpdateRequestType } from '../IssuesSupportRequests/components/tables/types';

export const setColorByStatus = (status: UpdateRequestType | typeof unresolveIssue | undefined): PaletteColor => {
  switch (status) {
    case UpdateRequestType.Accepted:
      return {
        light: yellow[200],
        main: yellow[600],
        dark: yellow[700],
        contrastText: 'rgba(0, 0, 0, 0.87)',
      };
    case UpdateRequestType.Declined:
      return {
        light: red[300],
        main: red[500],
        dark: red[700],
        contrastText: '#fff',
      };
    case UpdateRequestType.Resolved:
      return {
        light: green[200],
        main: green[500],
        dark: green[700],
        contrastText: '#fff',
      };
    case UpdateRequestType.BeingProcessed:
      return {
        light: blue[200],
        main: blue[400],
        dark: blue[600],
        contrastText: '#fff',
      };
    case unresolveIssue:
      return {
        light: orange[200],
        main: orange[500],
        dark: orange[700],
        contrastText: 'rgba(0, 0, 0, 0.87)',
      };
    default:
      return {
        light: grey[200],
        main: grey[400],
        dark: grey[600],
        contrastText: 'rgba(0, 0, 0, 0.6)',
      };
  }
};

export const chooseResolveColor = (ariaLabel?: string): string => {
  return ariaLabel === IResolvedColor.RESOLVE || ariaLabel === IResolvedColor.RESOLVED ? green[500] : orange[500];
};

export const renderText = (issue: AppIssueBrief, onHoverIssue: boolean) => {
  return onHoverIssue ? hoverText(issue) : defaultText(issue);
};
export const hoverText = (issue: AppIssueBrief) => {
  //hover text
  if (issue.resolved) {
    return 'Unresolve';
  } else {
    return 'Resolve';
  }
};
export const defaultText = (issue: AppIssueBrief) => {
  if (issue.resolved) {
    return IResolvedColor.RESOLVED;
  } else {
    return IResolvedColor.UNRESOLVED;
  }
};
