import { useCallback } from 'react';

import { useAddNote, useDeleteNote, useUpdateNote } from '/api';
import { snackbarService } from '/common/snackbarService';
import { useCommentDialog } from '/shared/EntityCommentDialog';

import { notesDialogOptions } from '../constants';
import { useBettleLookupContext } from '../context';

import { useBeetleLookupCommonInfo } from './useBeetleLookupInfo';

export const useNotesInfo = () => {
  const { generalId, cache } = useBettleLookupContext();
  const [commentDialogNode, openCommentDialog] = useCommentDialog();

  const {
    params: { env, type },
  } = useBeetleLookupCommonInfo();

  const { mutate: addNote } = useAddNote(type, {
    onSuccess: (data, variables) => {
      cache.addNote({ id: data || '', note: variables.note });
      snackbarService.success('Note was created');
    },
  });
  const onAddNote = useCallback(async () => {
    const note = await openCommentDialog('', notesDialogOptions);

    if (note) {
      addNote({ id: generalId, note: note, env: env });
    }
  }, [addNote, env, generalId, openCommentDialog]);

  const {
    mutate: updateIssueNote,
    variables: updateNoteVariables,
    isLoading: updateLoading,
  } = useUpdateNote(type, {
    onSuccess: (_, variables) => {
      cache.updateNote({ id: variables.noteId, note: variables.note });

      snackbarService.success('Note was updated');
    },
  });
  const onUpdateNote = useCallback(
    async (noteId: string, prevNote: string) => {
      const note = await openCommentDialog(prevNote, notesDialogOptions);
      if (note) {
        updateIssueNote({ id: generalId, noteId: noteId, note: note, env: env });
      }
    },
    [env, generalId, openCommentDialog, updateIssueNote]
  );

  const {
    mutate: deleteNote,
    variables: deleteNoteVariables,
    isLoading: deleteLoading,
  } = useDeleteNote(type, {
    onSuccess: (_, { noteId }) => {
      cache.removeNote(noteId);
      snackbarService.success('Note was deleted');
    },
  });
  const onDeleteNote = (noteId: string) => {
    deleteNote({ id: generalId, noteId: noteId, env });
  };

  return {
    variables: {
      deleteNoteVariables,
      updateNoteVariables,
      deleteLoading,
      updateLoading,
    },
    commentDialogNode,
    onUpdateNote,
    onAddNote,
    onDeleteNote,
  };
};
