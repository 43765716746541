import { useMemo } from 'react';

import { AppIssueBrief, MetaKeyWithCounter, AppSupportRequestBrief } from '@playq/services-beetle';

import { convertBeetleMetaToTagsWithCounters, isEmptyFilter } from '../helpers';
import { IUseBeetleIntelliTagsProps } from '../IssuesSupportRequests/components/types';

const EMPTY_TAGS: MetaKeyWithCounter[] = [];

export const useTags = (props: IUseBeetleIntelliTagsProps<AppIssueBrief | AppSupportRequestBrief>) => {
  const { allBeetles, filterBy, metaKeys } = props;

  const sortTagsByCount = (a: MetaKeyWithCounter, b: MetaKeyWithCounter) => b.count - a.count;

  const tagsWithCounter = useMemo(() => {
    if (isEmptyFilter(filterBy)) {
      return [...metaKeys].sort(sortTagsByCount);
    }
    return allBeetles
      .reduce<
        MetaKeyWithCounter[]
      >((accumulatedTags, currentRequest) => convertBeetleMetaToTagsWithCounters<AppIssueBrief | AppSupportRequestBrief>(accumulatedTags, currentRequest), [])
      .sort(sortTagsByCount);
  }, [allBeetles, filterBy, metaKeys]);

  return useMemo(
    () => ({
      otherTags: tagsWithCounter,
      requiredTags: EMPTY_TAGS,
      creationTags: EMPTY_TAGS,
    }),
    [tagsWithCounter]
  );
};
