import { useCallback } from 'react';
import { QueryClient, QueryKey, Updater } from '@tanstack/query-core';

import { OffsetLimit } from '@playq/services-shared';

export interface DeleteCacheBatchProps<R, S extends { id: string }> {
  mutate: (updater: Updater<R | undefined, R | undefined>) => void;
  total: number;
  nextKeys?: QueryKey;
  queryClient: QueryClient;
  getSerialize: (response: R) => S[];
  responseClass: (newArray: S[], iterator: OffsetLimit, currentTotal: number) => R;
}
export const useDeleteCacheBatch = <R extends object, ID extends { serialize: () => string }, S extends { id: string }>(
  args: DeleteCacheBatchProps<R, S>
) => {
  const { mutate, total, nextKeys, getSerialize, responseClass, queryClient } = args;
  return useCallback(
    (ids: ID[], updatedIterator: OffsetLimit) => {
      mutate((prev) => {
        if (!prev) {
          return;
        }
        const newResponse = prev;
        const serializeArray = getSerialize(newResponse);
        const selectedIndex = ids.map((id) => {
          return serializeArray.findIndex((item) => item.id === id.serialize());
        });
        if (!selectedIndex.includes(-1)) {
          const newArray = serializeArray.filter((_, i) => {
            return !selectedIndex.includes(i);
          });

          if (total - selectedIndex.length > updatedIterator.limit + updatedIterator.offset && nextKeys) {
            const nextPageIssues = queryClient.getQueryData(nextKeys) as R;
            return responseClass(
              newArray.concat(getSerialize(nextPageIssues).slice(0, selectedIndex.length)),
              updatedIterator,
              total - selectedIndex.length
            );
          }
          return responseClass(newArray, updatedIterator, total - selectedIndex.length);
        }
      });
    },
    [getSerialize, mutate, nextKeys, queryClient, responseClass, total]
  );
};
