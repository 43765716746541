import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useMemo } from 'react';

import { PermModule } from '@playq/octopus2-auth';
import { Environment } from '@playq/octopus-common';

import { appToolkit } from '/store';

import { IBeetleParams } from '../types';

export const useBeetleLookupCommonInfo = <I>() => {
  const { canWrite, canWriteLive } = useSelector(appToolkit.selectors.moduleAccess(PermModule.AudienceSupport));

  const params = useParams<IBeetleParams & I>();
  const location = useLocation();
  const readonly = params.env === Environment.Sandbox ? !canWrite : !canWriteLive;

  return useMemo(() => {
    return { readonly, params, location };
  }, [params, readonly, location]);
};
