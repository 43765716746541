import { AppSupportRequest, AppIssue } from '@playq/services-beetle';

import { formatUTCDate } from '/helpers';

export const generalInfo = (info: AppSupportRequest | AppIssue) => {
  const { author, id, messageTranslated, createdAt, message } = info;

  const genaralData = [
    {
      title: 'Message',
      value: messageTranslated || message,
      original: message,
    },
    {
      title: 'CreatedAt',
      value: formatUTCDate(createdAt),
    },
    {
      title: 'Author',
      value: author.user,
    },
    {
      title: 'ID',
      value: id.id,
    },
  ];
  if ('title' in info) {
    return [{ title: 'Title', value: info.titleTranslated || info.title, original: info.title }, ...genaralData];
  }
  return genaralData;
};
