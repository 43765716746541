import { QueryKey } from '@tanstack/query-core';

import { navigateToBeetleContent } from '/component/Support/BeetlesLookup/helpers';
import { SyntheticEventWithMetaKey } from '/common/models';

import { TIssueOrSupportBrief } from '../types';

const createContentState = ({ item, queryKeys }: { item: TIssueOrSupportBrief; queryKeys: QueryKey }) => ({
  app: item.id.app,
  id: item.id.id,
  currentPageQueryKey: queryKeys,
});

const handleRowClick = ({
  event,
  item,
  queryKeys,
  location,
}: {
  event: SyntheticEventWithMetaKey;
  item: TIssueOrSupportBrief;
  queryKeys: QueryKey;
  location: { pathname: string };
}) => {
  const contentState = createContentState({ item, queryKeys });

  navigateToBeetleContent({
    location,
    beetleState: contentState,
    event,
  });
};

export const rowClick = ({ queryKeys, location }: { queryKeys: QueryKey; location: { pathname: string } }) => {
  return (event: SyntheticEventWithMetaKey, item: TIssueOrSupportBrief) =>
    handleRowClick({ event, item, queryKeys, location });
};
