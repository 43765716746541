import { QueryKey } from '@tanstack/query-core';

import { GenericFailure, OffsetLimit } from '@playq/services-shared';
import {
  SupportRequestSortField,
  SupportRequestSort,
  IssueSort,
  MetaKeyWithCounter,
  Attachment,
  AttachmentSearchQuerySerialized,
  RequestStatus,
  IssueId,
  SupportRequestId,
} from '@playq/services-beetle';
import { Environment } from '@playq/octopus-common';
import { BeetleIssue, BeetleSupportRequest } from '@playq/octopus2-apps';

import { ICollectionTableQueryChange } from '/shared/Table';
import {
  IBeetleParams,
  Brief,
  TBettleId,
  TIssueOrRequestSort,
  TIssueOrSupportBrief,
  TUpdateBatchData,
  TUpdateData,
} from '/component/Support/BeetlesLookup/types';
import { IQuery } from '/common/models';
import { ModalProps } from '/hooks/useModal';

import { IUseExportBeetleToJiraDialogProps } from '../ExportBeetleToJiraDialog';

export type SupportRequestsQuery = IQuery<SupportRequestSortField, SupportRequestSort>;

export type IssuesQuery = IQuery<string, IssueSort>;
export type AttachmentQuery = IQuery<string>;

export interface BeetleTableProps<QueryType> {
  initialQuery: QueryType;
  metaKeys: MetaKeyWithCounter[];
  onQueryChange: (qc: ICollectionTableQueryChange<QueryType>) => void;
  attachment?: boolean;
  attachmentsQuery?: Partial<AttachmentSearchQuerySerialized>;
}
export interface IAttachmentsTableProps
  extends Omit<BeetleTableProps<IQuery<string>>, 'readonly' | 'metaKeys' | 'attachment' | 'attachmentsQuery'> {
  queryAttachments: {
    total: number;
    attachments: Attachment[];
    refetch: () => void;
    dataUpdatedAt: number;
    isLoading: boolean;
  };
}

export interface QueryEntities<R> extends TQueryCacheProps {
  entities: R[];
  isFetching: boolean;
  total: number;
  refetch: () => void;
  queryKeys: QueryKey;
  error: Error | GenericFailure | null;
}
export interface TQueryCacheProps {
  mutateUpdated: (updateData: TUpdateData) => void;
  mutateUpdatedBatchStatus: (updateData: TUpdateBatchData) => void;
  mutateDeletedEntity: (entityId: TBettleId) => void;
  removeItemBatch: (ids: TBettleId[], updatedIterator: OffsetLimit) => void;
}

export type ITableProps = {
  queryEntites: QueryEntities<TIssueOrSupportBrief>;
  selectedItems: Brief[];
  setSelectedItems: (selectedItems: Brief[]) => void;
} & BeetleTableProps<PersistQuery<TIssueOrRequestSort>>;

export type CommonQuery = IssuesQuery | SupportRequestsQuery;
export type PersistQuery<S> = IQuery<string, S>;

export type Sort = IssueSort | SupportRequestSort;

export interface ISharedProps {
  params: Pick<IBeetleParams, 'env' | 'type'>;
  queryKeys: QueryKey;
  currentKeys: QueryKey;
}
export interface TTrashButtonProps extends ISharedProps {
  selectedItems: Brief[];
  removeItemBatch: TQueryCacheProps['removeItemBatch'];
  iterator: OffsetLimit;
}

export enum UpdateRequestType {
  Accepted = 'Accepted',
  Declined = 'Declined',
  BeingProcessed = 'BeingProcessed',
  Resolved = 'Resolved',
  Sent = 'Sent',
}

export interface IUpdateBatchButtonProps {
  renderModal: (modal: ModalProps, handleStatusBatch: (mark: boolean | RequestStatus) => void) => void;
  support?: boolean;
  selectedItems: Brief[];
  mutateUpdatedBatchStatus: TQueryCacheProps['mutateUpdatedBatchStatus'];
}
export interface SelectedStatusListProps {
  modal: Omit<ModalProps, 'open' | 'close' | 'isOpen'>;
  updateRequest: (mark: boolean | RequestStatus) => void;
}

export interface IBeetleButtonGroup {
  beetleId: IssueId | SupportRequestId;
}
export interface ButtonProps extends IBeetleButtonGroup {
  params: IBeetleParams;
  readonly?: boolean;
}
export interface TEntityArg extends IBeetleButtonGroup {
  EntityClass: new () => BeetleIssue | BeetleSupportRequest;
  env: Environment;
}
export interface TExportJiraArg extends IBeetleButtonGroup {
  params: Pick<IBeetleParams, 'env' | 'type'>;
  location: Location;
  openDialog: (obj: IUseExportBeetleToJiraDialogProps) => void;
}
