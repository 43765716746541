import { Environment } from '@playq/octopus-common';

import { history } from '/Router/history';
import { SyntheticEventWithMetaKey } from '/common/models';
import { navigateToURL } from '/helpers';
import { snackbarService } from '/common/snackbarService';

import { BeetleContentStateType, ClickEventType } from '../IssuesSupportRequests/types';
import { BeetleNavigationParams, SupportContentType } from '../types';

export const getBeetlesRootURL = (appRouteName: string | undefined) => {
  if (!appRouteName) {
    return;
  }
  return `/apps/${appRouteName}/support/beetle`;
};

export const goToBeetles = (appRouteName: string | undefined, env: Environment) => () => {
  const url = getBeetlesRootURL(appRouteName);
  if (!url) {
    return;
  }
  history.push(url, { env });
};
export const goToBeetlesTableFromContent = (params: BeetleNavigationParams): void => {
  const url = getBeetlesTableURL(params);
  history.push(url, { env: params.env });
};
export const goToBeetlesTable =
  (type: SupportContentType, appName: string, env: Environment) => (event: ClickEventType) => {
    if (event.metaKey) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    event.preventDefault();
    if (!appName) {
      return;
    }
    const url = getBeetlesTableURL({ type, appName, env });
    if (url) {
      history.push(url, { env });
    }
  };

export const getBeetlesTableURL = ({ type, appName, env }: BeetleNavigationParams): string =>
  `/apps/${appName}/support/beetle/${type}/${env}`;

export const getBeetleURL = (location: { pathname: string }, state: Omit<BeetleContentStateType, 'env'>) => {
  const { app, id } = state;
  return `${location.pathname}/${app}/${id}`;
};

export const getBeetleFullURL = (location: Location, state: Omit<BeetleContentStateType, 'env'>) => {
  const pathToBeetlesList = location.pathname;
  if (!pathToBeetlesList) {
    return;
  }
  const fullUrl = window.location.href;
  const beetleURL = getBeetleURL(location, state);
  if (!beetleURL) {
    return;
  }
  const rootURL = fullUrl.split(pathToBeetlesList)[0];
  return `${rootURL}${beetleURL}`;
};

type GoToBeetleContentParams = {
  location: { pathname: string };
  beetleState: Omit<BeetleContentStateType, 'env'>;
  event?: SyntheticEventWithMetaKey;
};

export const navigateToBeetleContent = ({ location, beetleState, event }: GoToBeetleContentParams) => {
  const url = getBeetleURL(location, beetleState);
  navigateToURL({ url, state: beetleState, event });
};

export const getBeetlesURL = (appRouteName: string | undefined) =>
  appRouteName ? `/apps/${appRouteName}/support/beetle` : undefined;

export const blankClick = (e: ClickEventType) => {
  if (!e.metaKey) {
    e.preventDefault();
  }
};

export const handleBreadcrumbClickId = (
  type: SupportContentType,
  { appName, env, app, id }: BeetleContentStateType
) => {
  return (event: ClickEventType) => {
    if (!event.metaKey) {
      return;
    }
    if (!appName) {
      return;
    }
    return window.open(`/apps/${appName}/support/beetle/${type}/${env}/${app}/${id}`, '_blank');
  };
};
export const handleClick = ({ type, appName, env }: BeetleNavigationParams) => {
  return (event: ClickEventType) => {
    if (event.metaKey) {
      return window.open(`/apps/${appName}/support/beetle/${type}/${env}`, '_blank');
    }
    if (!appName) {
      return;
    }
    return history.push(getBeetlesTableURL({ type, appName, env }));
  };
};

export const blankClickBreadcrumbs =
  (appName: string, type: SupportContentType, env: Environment) => (e: SyntheticEventWithMetaKey) => {
    if (!e.metaKey) {
      e.preventDefault();
    } else {
      return window.open(`/apps/${appName}/support/beetle/${type}/${env}`, '_blank');
    }
  };
export const redirectToTablePage = ({ type, appName, env }: BeetleNavigationParams) => {
  goToBeetlesTableFromContent({ type, appName, env });
  snackbarService.genericFailure(`Could not retrieve content`);
};
