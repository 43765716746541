import { SvgIconComponent } from '@mui/icons-material';

import { Environment } from '@playq/octopus-common';
import { AccessLevel } from '@playq/octopus2-auth';

import { SupportContentType } from '../types';

export type ClickEventType = {
  stopPropagation(): unknown;
  preventDefault: () => void;
  metaKey: boolean;
};

export interface BeetleLookupItems {
  tooltipTitle: string;
  color: Colors;
  title: string;
  type: SupportContentType;
  icon: SvgIconComponent;
  attachments?: BeetleLookupItems;
  accessLevel?: AccessLevel;
}

export type BeetleContentStateType = {
  env: Environment;
  id: string;
  app: string;
  appName?: string;
};

export enum Colors {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  INFO = 'info',
}

export enum IResolvedColor {
  RESOLVED = 'resolved',
  UNRESOLVED = 'unresolved',
  RESOLVE = 'resolve',
  UNRESOLVE = 'unresolve',
}

export interface HoverButtonProps {
  hovered: string | undefined;
}
