import { IssueId, SupportRequestId } from '@playq/services-beetle';
import { BeetleIssue, BeetleSupportRequest } from '@playq/octopus2-apps';

import { getBeetleFullURL } from '/component/Support/BeetlesLookup/helpers';

import { TEntityArg, TExportJiraArg } from '../IssuesSupportRequests/components/tables/types';
import { SupportContentType } from '../types';

export const createEntity = ({ EntityClass, beetleId, env }: TEntityArg) => {
  const entity = new EntityClass();
  entity.env = env;

  if (entity instanceof BeetleIssue && beetleId instanceof IssueId) {
    entity.issue = beetleId;
  } else if (entity instanceof BeetleSupportRequest && beetleId instanceof SupportRequestId) {
    entity.request = beetleId;
  }
  return entity;
};
export const exportToJira = ({ location, params, beetleId, openDialog }: TExportJiraArg) => {
  const EntityClass =
    params.type === SupportContentType.ISSUES || params.type === SupportContentType.ISSUES_ATTACHMENTS
      ? BeetleIssue
      : BeetleSupportRequest;
  const url = getBeetleFullURL(location, { id: beetleId.id, app: beetleId.app });
  if (url) {
    const entity = createEntity({ EntityClass, beetleId, env: params.env });
    openDialog({
      url,
      entity,
    });
  }
  return;
};
